// Header.js
import React from 'react';
import sqanoIcon from '../../../assets/images/sqano-logo.png';
import './GeneratePdfHeader.css';

const GeneratePdfHeader = () => {
  return (
    <div className="generatepdf-header">
      <img src={sqanoIcon} alt="Sqano Logo" className="header-logo" />
    </div>
  );
};

export default GeneratePdfHeader;
