import React from "react"
import { XLg, ChevronRight } from "react-bootstrap-icons"
import logo from "assets/images/logo-dark.png"
import { socialIcons } from "../Utlits/socialIcons"
import { Link } from "react-router-dom"
import "./drawer.css"

const Drawer = ({ isSidebarActive, setIsSidebarActive }) => {
  return (
    <div className={`drawer ${isSidebarActive ? "active" : ""}`}>
      <div onClick={() => setIsSidebarActive(false)} className="drawer__close">
        <XLg />
      </div>
      <div className="drawer__logo">
        <Link to={""}>
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <p className="drawer__text">
        With SQANO, businesses can make better hiring decisions and gain a
        distinct advantage. Our technology is designed to empower organizations
        to hire with confidence and find the best talent globally.
      </p>
      <div className="drawer__info">
        <div className="drawer__info-item">
          <span className="drawer__info-title">Geographies</span>
          <span className="drawer__text">India - UK - Europe - USA</span>
        </div>
        <div className="drawer__info-item">
          <span className="drawer__info-title">Email</span>
          <a href="mailto:Contact@Sqano.Com" className="drawer__info-link">
            contact@sqano.Com
          </a>
        </div>
        <div className="drawer__info-item">
          <span className="drawer__info-title">Call Now</span>
          <a href="https://wa.me/8600088499" className="drawer__info-link">
            +91 86000 88499
          </a>
        </div>
      </div>
      <ul className="drawer__social">
        {socialIcons.map(({ icon, id, url }) => (
          <li key={id} className="drawer__social-item">
            <Link to={url} target="_blank" className="drawer__social-link">
              <i>{icon}</i>
            </Link>
          </li>
        ))}
      </ul>
      <Link to={"/contact"} className="drawer__button">
        <ChevronRight />
        <span>Let's Talk</span>
      </Link>
    </div>
  )
}

export default Drawer
