import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { useDispatch, useSelector } from "react-redux"
import { getUserByCreatedByRequest, getUsers } from "store/actions"
 
function createData(user) {
  return {
    ClientID: user._id,
    ClientName: `${user.firstName} ${user.lastName}`,
    ClientNo: user.contactNumber,
    EmailId: user.email,
    Location: user.address,
    history: [
      {
        Startdate: "2020-01-05",
        EndDate: "2024-08-15", // Example date format
        subscription: "Free",
      },
    ],
  }
}
 
function CollapsibleTable({users}) {
  const [selectedRow, setSelectedRow] = useState(null)
  


  
 
  const handleRowClick = rowId => {
    setSelectedRow(rowId === selectedRow ? null : rowId)
  }

 
  return (
    <TableContainer component={Paper} className="mt-4">
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className="custom-table-cell">Client ID</TableCell>
            <TableCell className="custom-table-cell">Client Name</TableCell>
            <TableCell className="custom-table-cell">Client No.</TableCell>
            <TableCell className="custom-table-cell">Email ID</TableCell>
            <TableCell className="custom-table-cell">Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {users.map(user => { */}
          {users.map(user => {
              const row = createData(user)
              return (
                <React.Fragment key={row.ClientID}>
                  <TableRow
                    sx={{ "& > *": { borderBottom: "unset" } }}
                    onClick={() => handleRowClick(row.ClientID)}
                    selected={selectedRow === row.ClientID}
                    hover
                  >
                    <TableCell>
                      <IconButton size="small">
                        {selectedRow === row.ClientID ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>{row.ClientID}</TableCell>
                    <TableCell>{row.ClientName}</TableCell>
                    <TableCell>{row.ClientNo}</TableCell>
                    <TableCell>{row.EmailId}</TableCell>
                    <TableCell>{row.Location}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={selectedRow === row.ClientID}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Subscription Details
                          </Typography>
                          <Table size="small" aria-label="subscription-details">
                            <TableHead>
                              <TableRow>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                                <TableCell>Subscription</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.history.map((historyRow, index) => (
                                <TableRow key={index}>
                                  <TableCell>{historyRow.Startdate}</TableCell>
                                  <TableCell>{historyRow.EndDate}</TableCell>
                                  <TableCell>
                                    {historyRow.subscription}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
 
export default CollapsibleTable
 
